import { Button, Image, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { MainSubStructureModel, type PaginationData, RenderByPermission, request } from '../../../utils';
import EditorModel from './modal/editorModel';
import type { PlatformSysBusinessCardVO } from './type';

/**
 * 业务卡片管理模型
 */
export default class Model {
  constructor() {
    this.mainSubStructureModel?.gridModel?.onQuery();
  }

  /** 编辑模型 */
  public accessor editorModel = new EditorModel(this);

  /** 主从结构模型 */
  public accessor mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      rowHeight: 80,
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 150,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_70`}>
                  <Button
                    onClick={() => {
                      this.editorModel.onOpen(row);
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'cardName',
          name: '姓名',
        },
        {
          key: 'roleName',
          name: '角色',
        },
        {
          key: 'mainUrl',
          name: '形象照',
          formatter: ({ row }) =>
            row.mainUrl && (
              <Image
                src={row.mainUrl}
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                }}
              />
            ),
        },
        {
          key: 'wxMemo',
          name: '微信备注',
        },
        {
          key: 'cityList',
          name: '服务城市',
          formatter: ({ row }) => {
            const { cityList } = row;
            return cityList?.length
              ? cityList.map((v: { id: number; name: string }) => <Tag key={v.id}>{v.name}</Tag>)
              : '';
          },
        },
        {
          key: 'keyWord',
          name: '关键词',
          formatter: ({ row }) => {
            if (row.keyWord) {
              const keyWord = JSON.parse(row.keyWord);
              return keyWord.map((v: string) => <Tag key={v}>{v}</Tag>);
            } else {
              return <Tag>无</Tag>;
            }
          },
        },
        {
          key: 'briefIntroduction',
          name: '简介',
          with: 300,
          formatter: ({ row }) => <Tooltip title={row.briefIntroduction}>{row.briefIntroduction}</Tooltip>,
        },
        {
          key: 'wechatCode',
          name: '二维码',
          formatter: ({ row }) =>
            row.wechatCode && (
              <Image
                src={row.wechatCode}
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                }}
              />
            ),
        },
        {
          key: 'latestUpdateByName',
          name: '更新人',
        },
        {
          key: 'latestUpdateTime',
          name: '最后更新时间',
          formatter: ({ row }) => dayjs(row.latestUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'adminId',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: true,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      /**
       * 查询接口
       * @param {object} params - 请求参数
       * @returns {Promise<PaginationData<PlatformSysBusinessCardVO>>} 请求结果
       */
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<PaginationData<PlatformSysBusinessCardVO>>({
          url: '/pbb/platform/broker/businessCard/list/page',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  });
}
