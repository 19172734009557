import { type FormInstance, message } from 'antd';
import { action, observable, set, toJS, values } from 'mobx';
import type { BaseData, FilterTreeSelectItem } from '../../../../utils';
import { extractValuesByKey, request, transformKeys } from '../../../../utils';
import type Model from '../model';
import type { PlatformSysBusinessCardVO } from '../type';

export default class EditorModel {
  constructor(public mainModel?: Model) {
    this.getCityList();
  }

  @observable public visible = false;

  @observable public saveLoading = false;

  @observable public cityOption: any = [];

  @observable public currentCity: any = [];

  public ref: FormInstance;

  public selectRef: any;

  public _transformValues = (list): any => {
    list?.forEach((item) => {
      item.value = String(item.value);
      if (item.children && Array.isArray(item.children)) {
        this._transformValues(item.children);
      }
    });
    return list;
  };
  @action public onOpen = (row: PlatformSysBusinessCardVO) => {
    this.visible = true;
    const { cityList } = row;
    this.currentCity = transformKeys(cityList, { id: 'value', name: 'label' });
    console.log(this._transformValues(toJS(this.currentCity)), ';;;;;;');

    const keyWord = row.keyWord ? (JSON.parse(row.keyWord)?.length ? JSON.parse(row.keyWord) : null) : null;
    setTimeout(() => {
      this.ref?.setFieldsValue({
        ...row,
        keyWord,
        cityList: this._transformValues(this.currentCity),
      });
    });
  };

  @action public onCancel = () => {
    this.visible = false;
    this.ref.resetFields();
  };

  public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };

  public preSelectAll = false;

  @action public changeCityIds = (ids: string[]) => {
    console.log(ids);
    const selectedItems = this.filterTree(toJS(this.cityOption), new Set(ids));
    const allItems = transformKeys(this.cityOption, { value: 'value' });
    const nowSelectAll = ids.includes('0');

    if (this.preSelectAll) {
      if (!nowSelectAll) {
        this.ref.setFieldsValue({ cityList: [] });
        this.preSelectAll = false;
      } else if (ids[ids.length - 1] != '0') {
        this.ref.setFieldsValue({ cityList: selectedItems.slice(1) });
        this.preSelectAll = false;
      }
    } else {
      if (nowSelectAll) {
        this.preSelectAll = true;
        this.ref.setFieldsValue({ cityList: allItems });
      } else {
        this.preSelectAll = false;
        this.ref.setFieldsValue({ cityList: selectedItems });
      }
    }
  };

  @action public filterTree = (data: FilterTreeSelectItem[], ids: Set<string>, count = 0) => {
    const filterNode = (node: FilterTreeSelectItem) => {
      if (!node.children) {
        node.children = [];
      }
      const filteredChildren = node.children.map(filterNode).filter((child) => child !== null);
      if (ids.has(node.value) || filteredChildren.length > 0) {
        return {
          ...node,
          children: filteredChildren,
        };
      }
      return null;
    };
    return data.map(filterNode).filter((node) => node !== null);
  };

  @action public getCityList = async () => {
    request<BaseData<any>>({
      url: '/baseinfo/area/province/city/list',
      method: 'get',
    }).then((res) => {
      let transformedData = transformKeys(res.data, {
        name: 'label',
        id: 'value',
        childrenList: 'children',
      });

      const allCity = {
        label: '全国',
        value: '0',
        children: [],
      };

      const processChildren = (nodes: any) => {
        return nodes.map((item) => {
          if (item.children && item.children.length === 1) {
            return {
              ...item,
              ...item.children[0],
              children: undefined,
            };
          } else if (item.children) {
            return item;
          }
          return item;
        });
      };

      transformedData = processChildren(transformedData);
      this.cityOption = [allCity, ...transformedData];
      // this.filterSet.filterItems.updateFilterItem([{ field: 'cityIdList', treeData: transformedData }]);
    });
  };

  @action public onFinish = async () => {
    if (this.saveLoading) {
      return;
    }
    this.saveLoading = true;

    try {
      const values = await this.ref.getFieldsValue(true);

      let postList = values.cityList;
      if (values.cityList?.[0].label === '全国') {
        postList = values.cityList.slice(1);
      }

      const res = await request<BaseData<any>>({
        url: '/pbb/platform/broker/businessCard/edit',
        method: 'POST',
        data: {
          ...values,
          keyWord: JSON.stringify(values.keyWord),
          cityList: transformKeys(postList, { value: 'id', label: 'name' }),
        },
      });
      this.onCancel();
      this.mainModel.mainSubStructureModel?.gridModel?.onQuery();
      message.success('编辑成功');
    } catch (error) {
      console.log(error);
    } finally {
      this.saveLoading = false;
    }
  };
}
