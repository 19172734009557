import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { MainSubStructure } from '../../../utils/egGrid';
import Editor from './modal/editor';
import Model from './model';

export default observer(() => {
  const store = useMemo(() => new Model(), []);
  const { mainSubStructureModel, editorModel } = store;

  return (
    <div className="h-screen">
      {/* <AgGridReact {...agGridReactModel} /> */}
      <MainSubStructure store={mainSubStructureModel} />
      <Editor store={editorModel} />
    </div>
  );
});
