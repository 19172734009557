import Store from '@/pages/employment/store';
import { EpUpload } from '@/utils';
import compressImgOrVideo from '@/utils/compress';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Form, Image, Input, Modal, Tag, TreeSelect, Upload, message } from 'antd';
import type { RcFile } from 'antd/es/upload';
import { observer } from 'mobx-react';
import { type ChangeEvent, useRef, useState } from 'react';
import type Model from './editorModel';

// 新增或编辑需求配置信息
export default observer(({ store }: { store: Model }) => (
  <Modal
    centered={true}
    open={store.visible}
    onOk={() => {
      store.ref?.submit();
    }}
    maskClosable={false}
    onCancel={store.onCancel}
    confirmLoading={store.saveLoading}
    title="编辑名片"
  >
    <Form
      ref={store.setRef}
      onFinish={store.onFinish}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
    >
      <Form.Item
        name="cardName"
        label="姓名"
        rules={[
          {
            required: true,
            message: '请输入名片名称',
          },
        ]}
      >
        <Input
          disabled={true}
          style={{ border: 'unset', background: 'unset', color: '#000' }}
        />
      </Form.Item>
      <Form.Item
        name="roleName"
        label="角色"
        rules={[
          {
            required: true,
            message: '请输入角色名称',
          },
        ]}
      >
        <Input
          disabled={true}
          style={{ border: 'unset', background: 'unset', color: '#000' }}
        />
      </Form.Item>
      <ImageUpload
        name="mainUrl"
        label="形象照"
        message="请上传形象照"
      />
      <Form.Item
        name="wxMemo"
        label="微信备注"
        rules={[
          {
            required: true,
            message: '请输入',
          },
        ]}
      >
        <Input
          className="flex-1"
          placeholder="请输入微信号备注"
        />
      </Form.Item>
      <Form.Item
        name="cityList"
        label="服务城市"
        rules={[
          {
            required: true,
            message: '请选择',
          },
        ]}
      >
        <TreeSelect
          allowClear={true}
          // optionFilterProp="label"
          // options={store.cityOption}
          treeData={store.cityOption}
          maxTagCount={10}
          placeholder="请选择"
          multiple={true}
          treeCheckable={true}
          // defaultValue={store.currentCity}
          onChange={(ids: string[]) => {
            store.changeCityIds(ids);
          }}
          // labelInValue={true}
        />
      </Form.Item>
      <KeyWordTags
        name="keyWord"
        label="关键词"
        message="请输入关键词"
      />
      <Form.Item
        name="briefIntroduction"
        label="简介"
        rules={[
          {
            required: true,
            message: '请输入简介',
          },
        ]}
      >
        <Input.TextArea
          maxLength={200}
          showCount={true}
        />
      </Form.Item>
      <ImageUpload
        name="wechatCode"
        label="企微二维码"
        message="请上传企微二维码"
      />
    </Form>
  </Modal>
));

const ImageUpload = (props: { readonly name: string; readonly label: string; readonly message: string }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => {
        return prevValues[props.name] !== nextValues[props.name];
      }}
    >
      {({ getFieldValue, setFieldValue }) => {
        const imageUrl = getFieldValue(props.name);
        const customRequest = async (options: any) => {
          console.log(options.file, 'options.file');

          setLoading(true);
          const unit = options.file.name.split(',');
          const file: any = await compressImgOrVideo(options.file);
          if (file === false) {
            return;
          }
          const url = await EpUpload.singleUpload({
            attachmentType: 1,
            module: 'opus',
            file: file,
          });
          setFieldValue(props.name, url);
          setLoading(false);
        };

        const beforeUpload = (file: RcFile) => {
          if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp') {
            message.error('图片格式只能为jpg、jpeg、png、webp');
            return false;
          }
          // 限制文件大小，例如限制为 2MB
          const isLt6M = file.size / 1024 / 1024 < 6;
          if (!isLt6M) {
            console.error('图片需要小于6MB!');
            return false;
          }
          return true;
        };

        return (
          <Form.Item
            label={props.label}
            name={props.name}
            getValueFromEvent={(e) => imageUrl}
            validateTrigger={false}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              accept=".jpg,.jpeg,.png,.webp"
            >
              {imageUrl ? (
                <Image
                  src={imageUrl}
                  preview={false}
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <button
                  style={{
                    border: 0,
                    background: 'none',
                  }}
                  type="button"
                >
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div className="mt-2">上传</div>
                </button>
              )}
            </Upload>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

const KeyWordTags = (props: { readonly name: string; readonly label: string; readonly message?: string }) => {
  const inputRef = useRef<InputRef>(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => {
        return prevValues[props.name] !== nextValues[props.name];
      }}
    >
      {({ getFieldValue, setFieldValue }) => {
        // getValueFromEvent 要返回原始数据 这里返回的是 tags
        const tags: string[] = getFieldValue(props.name);
        const tagsArr: string[] = tags || [];
        const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
          setInputValue(e.target.value);
        };

        const handleInputConfirm = () => {
          if (inputValue && !tagsArr.includes(inputValue)) {
            setFieldValue(props.name, [...tagsArr, inputValue]);
          }
          setInputVisible(false);
          setInputValue('');
        };

        const showInput = () => {
          if (tagsArr.length < 2) {
            setInputVisible(true);
          } else {
            message.error('最多只能添加2个标签');
          }
        };
        const handleClose = (e: React.MouseEvent<HTMLElement>, removedTag: string) => {
          e.preventDefault();
          setFieldValue(
            props.name,
            tagsArr.filter((tag) => tag !== removedTag),
          );
        };

        return (
          <Form.Item
            label={props.label}
            name={props.name}
            getValueFromEvent={() => tags}
            rules={[{ required: true, message: props.message }]}
          >
            <div className="flex items-center ">
              {tagsArr?.map((item, index) => (
                <Tag
                  key={`${index.toString()}`}
                  closable={true}
                  style={{ userSelect: 'none' }}
                  onClose={(e) => handleClose(e, item)}
                >
                  <span>{item}</span>
                </Tag>
              ))}

              {inputVisible ? (
                <Input
                  className="w-24"
                  ref={inputRef}
                  type="text"
                  size="small"
                  value={inputValue}
                  maxLength={6}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              ) : (
                <Tag
                  icon={<PlusOutlined />}
                  onClick={showInput}
                >
                  自定义标签
                </Tag>
              )}
            </div>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};
